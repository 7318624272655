import {authRole} from '../shared/constants/AppConst';

const routesConfig = [
  {
    id: 'app',
    title: 'Application',
    messageId: 'sidebar.application',
    type: 'group',
    children: [
      {
        id: 'admin',
        title: 'Admin',
        messageId: '',
        type: '',
        icon: '',
        url: '/admin',
      },
      {
        id: 'dashboards',
        title: 'Dashboards',
        messageId: 'sidebar.app.dashboard',
        type: 'collapse',
        icon: 'dashboard',
        children: [
          {
            id: 'healthCare',
            title: 'Health Care',
            messageId: 'sidebar.healthCare',
            type: 'item',
            auth: authRole.user,
            url: '/dashboards/health-care',
          },
          {
            id: 'e-commerce',
            title: 'E-Commerce',
            messageId: 'sidebar.app.dashboard.eCommerce',
            type: 'item',
            url: '/dashboards/e-commerce',
          },
          {
            id: 'academy',
            title: 'Academy',
            messageId: 'sidebar.app.dashboard.academy',
            type: 'item',
            url: '/dashboards/academy',
          },
          {
            id: 'analytics',
            title: 'Analytics',
            messageId: 'sidebar.app.dashboard.analytics',
            type: 'item',
            url: '/dashboards/analytics',
          },
          {
            id: 'crm',
            title: 'CRM',
            messageId: 'sidebar.app.dashboard.crm',
            type: 'item',
            url: '/dashboards/crm',
          },
          {
            id: 'crypto',
            title: 'Crypto',
            messageId: 'sidebar.app.dashboard.crypto',
            type: 'item',
            url: '/dashboards/crypto',
          },
        ],
      },
      {
        id: 'metrics',
        title: 'Metrics',
        messageId: 'sidebar.app.metrics',
        type: 'item',
        icon: 'insert_chart',
        url: '/dashboards/metrics',
      },
      {
        id: 'widgets',
        title: 'Widgets',
        messageId: 'sidebar.app.widgets',
        type: 'item',
        icon: 'widgets',
        url: '/dashboards/widgets',
      },
      {
        id: 'ecommerce',
        title: 'Ecommerce',
        messageId: 'sidebar.ecommerce',
        type: 'collapse',
        icon: 'shopping_cart',
        children: [
          {
            id: 'products',
            title: 'Products',
            messageId: 'sidebar.ecommerce.products',
            type: 'item',
            url: '/ecommerce/products',
          },
          {
            id: 'product_detail',
            title: 'Product Detail',
            messageId: 'sidebar.ecommerce.productDetail',
            type: 'item',
            url: '/ecommerce/product_detail',
          },
          {
            id: 'orders',
            title: 'Orders',
            messageId: 'sidebar.ecommerce.orders',
            type: 'item',
            url: '/ecommerce/orders',
          },
          {
            id: 'customers',
            title: 'Customers',
            messageId: 'sidebar.ecommerce.customers',
            type: 'item',
            url: '/ecommerce/customers',
          },
          {
            id: 'cart',
            title: 'Cart',
            messageId: 'sidebar.ecommerce.cart',
            type: 'item',
            url: '/ecommerce/cart',
          },
          {
            id: 'checkout',
            title: 'Checkout',
            messageId: 'sidebar.ecommerce.checkout',
            type: 'item',
            url: '/ecommerce/checkout',
          },
          {
            id: 'confirmation',
            title: 'Confirmation',
            messageId: 'sidebar.ecommerce.confirmation',
            type: 'item',
            url: '/ecommerce/confirmation',
          },
          {
            id: 'invoice-1',
            title: 'Invoice 1',
            messageId: 'sidebar.ecommerce.invoice1',
            type: 'item',
            url: '/ecommerce/invoice-1',
          },
          {
            id: 'invoice-2',
            title: 'Invoice 2',
            messageId: 'sidebar.ecommerce.invoice2',
            type: 'item',
            url: '/ecommerce/invoice-2',
          },
        ],
      },
      {
        id: 'apps',
        title: 'Apps',
        messageId: 'sidebar.apps',
        type: 'collapse',
        icon: 'apps',
        children: [
          {
            id: 'mail',
            title: 'Mail',
            messageId: 'sidebar.apps.mail',
            type: 'item',
            count: 4,
            url: '/apps/mail',
          },
          {
            id: 'todo',
            title: 'ToDo',
            messageId: 'sidebar.apps.todo',
            type: 'item',
            count: 6,
            color: '#48bb78',
            url: '/apps/todo',
          },
          {
            id: 'contact',
            title: 'Contact',
            messageId: 'sidebar.apps.contact',
            type: 'item',
            url: '/apps/contact',
          },
          {
            id: 'scrum-board',
            title: 'Scrum Board',
            messageId: 'sidebar.apps.scrumboard',
            type: 'item',
            url: '/apps/scrum-board',
          },
          {
            id: 'chat',
            title: 'Chat',
            messageId: 'sidebar.apps.chat',
            type: 'item',
            url: '/apps/chat',
          },
          {
            id: 'wall',
            title: 'Wall',
            messageId: 'sidebar.apps.wall',
            type: 'item',
            url: '/apps/wall',
          },
        ],
      },
    ],
  },
  {
    id: 'mui',
    title: 'MUI Components',
    messageId: 'sidebar.mui',
    type: 'group',
    children: [
      {
        id: 'inputs',
        title: 'Inputs',
        messageId: 'sidebar.mui.inputs',
        type: 'collapse',
        icon: 'input',
        children: [
          {
            id: 'buttons',
            title: 'Buttons',
            messageId: 'sidebar.mui.inputs.buttons',
            type: 'item',
            url: '/mui/inputs/buttons',
          },
          {
            id: 'button-group',
            title: 'Button Group',
            messageId: 'sidebar.mui.inputs.buttonGroup',
            type: 'item',
            url: '/mui/inputs/button-group',
          },
          {
            id: 'checkboxes',
            title: 'Checkboxes',
            messageId: 'sidebar.mui.inputs.checkboxes',
            type: 'item',
            url: '/mui/inputs/checkboxes',
          },
          {
            id: 'fab',
            title: 'Floating Action Button',
            messageId: 'sidebar.mui.inputs.fab',
            type: 'item',
            url: '/mui/inputs/fab',
          },
          {
            id: 'date-time',
            title: 'Date/Time',
            messageId: 'sidebar.mui.inputs.dateTime',
            type: 'item',
            url: '/mui/inputs/date-time',
          },
          {
            id: 'radios',
            title: 'Radio Button',
            messageId: 'sidebar.mui.inputs.radio',
            type: 'item',
            url: '/mui/inputs/radios',
          },
          {
            id: 'selects',
            title: 'Selects',
            messageId: 'sidebar.mui.inputs.selects',
            type: 'item',
            url: '/mui/inputs/selects',
          },
          {
            id: 'slider',
            title: 'Slider',
            messageId: 'sidebar.mui.inputs.slider',
            type: 'item',
            url: '/mui/inputs/slider',
          },
          {
            id: 'switches',
            title: 'Switches',
            messageId: 'sidebar.mui.inputs.switches',
            type: 'item',
            url: '/mui/inputs/switches',
          },
          {
            id: 'textField',
            title: 'Text Field',
            messageId: 'sidebar.mui.inputs.textField',
            type: 'item',
            url: '/mui/inputs/text-fields',
          },
          {
            id: 'transfer',
            title: 'Transfer List',
            messageId: 'sidebar.mui.inputs.transfer',
            type: 'item',
            url: '/mui/inputs/transfer-list',
          },
        ],
      },
      {
        id: 'navigation',
        title: 'Navigation',
        messageId: 'sidebar.mui.navigation',
        type: 'collapse',
        icon: 'double_arrow',
        children: [
          {
            id: 'bottomNavigation',
            title: 'Bottom Navigation',
            messageId: 'sidebar.mui.navigation.bottom',
            type: 'item',
            url: '/mui/navigation/bottom-navigation',
          },
          {
            id: 'breadcrumbs',
            title: 'Breadcrumbs',
            messageId: 'sidebar.mui.navigation.breadcrumbs',
            type: 'item',
            url: '/mui/navigation/breadcrumbs',
          },
          {
            id: 'drawers',
            title: 'Drawers',
            messageId: 'sidebar.mui.navigation.drawers',
            type: 'item',
            url: '/mui/navigation/drawers',
          },
          {
            id: 'links',
            title: 'Links',
            messageId: 'sidebar.mui.navigation.links',
            type: 'item',
            url: '/mui/navigation/links',
          },
          {
            id: 'menus',
            title: 'Menus',
            messageId: 'sidebar.mui.navigation.menus',
            type: 'item',
            url: '/mui/navigation/menus',
          },
          {
            id: 'steppers',
            title: 'Steppers',
            messageId: 'sidebar.mui.navigation.steppers',
            type: 'item',
            url: '/mui/navigation/steppers',
          },
          {
            id: 'tabs',
            title: 'Tabs',
            messageId: 'sidebar.mui.navigation.tabs',
            type: 'item',
            url: '/mui/navigation/tabs',
          },
        ],
      },
      {
        id: 'surface',
        title: 'Surface',
        messageId: 'sidebar.mui.surface',
        type: 'collapse',
        icon: 'surround_sound',
        children: [
          {
            id: 'appBar',
            title: 'App Bar',
            messageId: 'sidebar.mui.surface.appBar',
            type: 'item',
            url: '/mui/surface/app-Bar',
          },
          {
            id: 'paper',
            title: 'Paper',
            messageId: 'sidebar.mui.surface.paper',
            type: 'item',
            url: '/mui/surface/paper',
          },
          {
            id: 'cards',
            title: 'Cards',
            messageId: 'sidebar.mui.surface.cards',
            type: 'item',
            url: '/mui/surface/cards',
          },
          {
            id: 'accordion',
            title: 'Accordion',
            messageId: 'sidebar.mui.surface.accordion',
            type: 'item',
            url: '/mui/surface/accordion',
          },
        ],
      },
      {
        id: 'feedback',
        title: 'Feedback',
        messageId: 'sidebar.mui.feedback',
        type: 'collapse',
        icon: 'notification_important',
        children: [
          {
            id: 'progress',
            title: 'Progress',
            messageId: 'sidebar.mui.feedback.progress',
            type: 'item',
            url: '/mui/feedback/progress',
          },
          {
            id: 'dialog',
            title: 'Dialog',
            messageId: 'sidebar.mui.feedback.dialog',
            type: 'item',
            url: '/mui/feedback/dialog',
          },
          {
            id: 'snackbars',
            title: 'Snackbars',
            messageId: 'sidebar.mui.feedback.snackbars',
            type: 'item',
            url: '/mui/feedback/snackbars',
          },
        ],
      },
      {
        id: 'dataDisplay',
        title: 'Data Display',
        messageId: 'sidebar.mui.dataDisplay',
        type: 'collapse',
        icon: 'screen_share',
        children: [
          {
            id: 'avatars',
            title: 'Avatars',
            messageId: 'sidebar.mui.dataDisplay.avatars',
            type: 'item',
            url: '/mui/db-display/avatars',
          },
          {
            id: 'badges',
            title: 'Badges',
            messageId: 'sidebar.mui.dataDisplay.badges',
            type: 'item',
            url: '/mui/db-display/badges',
          },
          {
            id: 'chips',
            title: 'Chips',
            messageId: 'sidebar.mui.dataDisplay.chips',
            type: 'item',
            url: '/mui/db-display/chips',
          },
          {
            id: 'divider',
            title: 'Divider',
            messageId: 'sidebar.mui.dataDisplay.divider',
            type: 'item',
            url: '/mui/db-display/divider',
          },
          {
            id: 'lists',
            title: 'Lists',
            messageId: 'sidebar.mui.dataDisplay.lists',
            type: 'item',
            url: '/mui/db-display/lists',
          },
          {
            id: 'tables',
            title: 'Tables',
            messageId: 'sidebar.mui.dataDisplay.tables',
            type: 'item',
            url: '/mui/db-display/tables',
          },
          {
            id: 'tooltip',
            title: 'Tooltip',
            messageId: 'sidebar.mui.dataDisplay.tooltip',
            type: 'item',
            url: '/mui/db-display/tooltip',
          },
          {
            id: 'typography',
            title: 'Typography',
            messageId: 'sidebar.mui.dataDisplay.typography',
            type: 'item',
            url: '/mui/db-display/typography',
          },
        ],
      },
      {
        id: 'util',
        title: 'Util',
        messageId: 'sidebar.mui.util',
        type: 'collapse',
        icon: 'account_balance_wallet',
        children: [
          {
            id: 'alert',
            title: 'Alert',
            messageId: 'sidebar.mui.util.alert',
            type: 'item',
            url: '/mui/utility/alert',
          },
          {
            id: 'modal',
            title: 'Modal',
            messageId: 'sidebar.mui.util.modal',
            type: 'item',
            url: '/mui/utility/modal',
          },
          {
            id: 'pagination',
            title: 'Pagination',
            messageId: 'sidebar.mui.util.pagination',
            type: 'item',
            url: '/mui/utility/pagination',
          },
          {
            id: 'popover',
            title: 'Popover',
            messageId: 'sidebar.mui.util.popover',
            type: 'item',
            url: '/mui/utility/popover',
          },
          {
            id: 'popper',
            title: 'Popper',
            messageId: 'sidebar.mui.util.popper',
            type: 'item',
            url: '/mui/utility/popper',
          },
          {
            id: 'rating',
            title: 'Rating',
            messageId: 'sidebar.mui.util.rating',
            type: 'item',
            url: '/mui/utility/rating',
          },
          {
            id: 'skeleton',
            title: 'Skeleton',
            messageId: 'sidebar.mui.util.skeleton',
            type: 'item',
            url: '/mui/utility/skeleton',
          },
          {
            id: 'speed-dial',
            title: 'Speed Dial',
            messageId: 'sidebar.mui.util.speedDial',
            type: 'item',
            url: '/mui/utility/speed-dial',
          },
          {
            id: 'toggle-buttons',
            title: 'Toggle Buttons',
            messageId: 'sidebar.mui.util.toggleButtons',
            type: 'item',
            url: '/mui/utility/toggle-buttons',
          },
          {
            id: 'tree-view',
            title: 'Tree View',
            messageId: 'sidebar.mui.util.treeView',
            type: 'item',
            url: '/mui/utility/tree-view',
          },
        ],
      },
    ],
  },
  {
    id: 'third-party',
    title: 'Libs',
    messageId: 'sidebar.libs',
    type: 'group',
    children: [
      {
        id: 'google-map',
        title: 'Google Map',
        messageId: 'sidebar.googleMap',
        type: 'collapse',
        icon: 'map',
        children: [
          {
            id: 'simple',
            title: 'Simple',
            messageId: 'sidebar.googleMap.simple',
            type: 'item',
            url: '/third-party/google-map/simple',
          },
          {
            id: 'directions',
            title: 'Directions',
            messageId: 'sidebar.googleMap.directions',
            type: 'item',
            url: '/third-party/google-map/directions',
          },
          {
            id: 'drawing-view',
            title: 'DrawingView',
            messageId: 'sidebar.googleMap.drawingView',
            type: 'item',
            url: '/third-party/google-map/drawing-view',
          },
          {
            id: 'event-handler',
            title: 'Event Handler',
            messageId: 'sidebar.googleMap.eventHandler',
            type: 'item',
            url: '/third-party/google-map/event-handler',
          },
          {
            id: 'geolocation',
            title: 'GeoLocation',
            messageId: 'sidebar.googleMap.geoLocation',
            type: 'item',
            url: '/third-party/google-map/geolocation',
          },
          {
            id: 'km-layer',
            title: 'KmLayer',
            messageId: 'sidebar.googleMap.kmLayer',
            type: 'item',
            url: '/third-party/google-map/km-layer',
          },
          {
            id: 'map-clustering',
            title: 'MapClustering',
            messageId: 'sidebar.googleMap.mapClustering',
            type: 'item',
            url: '/third-party/google-map/map-clustering',
          },
          {
            id: 'map-overlay',
            title: 'MapOverlay',
            messageId: 'sidebar.googleMap.mapOverlay',
            type: 'item',
            url: '/third-party/google-map/map-overlay',
          },
          {
            id: 'map-with-search-box',
            title: 'MapWithSearchBox',
            messageId: 'sidebar.googleMap.mapWithSearchBox',
            type: 'item',
            url: '/third-party/google-map/map-with-search-box',
          },
          {
            id: 'popup-info',
            title: 'PopUpInfo',
            messageId: 'sidebar.googleMap.popUpInfo',
            type: 'item',
            url: '/third-party/google-map/popup-info',
          },
          {
            id: 'street-view',
            title: 'StreetView',
            messageId: 'sidebar.googleMap.streetView',
            type: 'item',
            url: '/third-party/google-map/street-view',
          },
          {
            id: 'styled',
            title: 'Styled',
            messageId: 'sidebar.googleMap.styled',
            type: 'item',
            url: '/third-party/google-map/styled',
          },
          {
            id: 'traffic-layer',
            title: 'TrafficLayer',
            messageId: 'sidebar.googleMap.trafficLayer',
            type: 'item',
            url: '/third-party/google-map/traffic-layer',
          },
        ],
      },
      {
        id: 'recharts',
        title: 'Recharts',
        messageId: 'sidebar.recharts',
        type: 'collapse',
        icon: 'bar_chart',
        children: [
          {
            id: 'area',
            title: 'Area Chart',
            messageId: 'sidebar.recharts.areaChart',
            type: 'item',
            url: '/third-party/recharts/area',
          },
          {
            id: 'bar',
            title: 'Bar Chart',
            messageId: 'sidebar.recharts.barChart',
            type: 'item',
            url: '/third-party/recharts/bar',
          },
          {
            id: 'composed',
            title: 'Composed Chart',
            messageId: 'sidebar.recharts.composedChart',
            type: 'item',
            url: '/third-party/recharts/composed',
          },
          {
            id: 'line',
            title: 'Line Chart',
            messageId: 'sidebar.recharts.lineChart',
            type: 'item',
            url: '/third-party/recharts/line',
          },
          {
            id: 'pie',
            title: 'Pie Chart',
            messageId: 'sidebar.recharts.pieChart',
            type: 'item',
            url: '/third-party/recharts/pie',
          },
          {
            id: 'radar',
            title: 'Radar Chart',
            messageId: 'sidebar.recharts.radarChart',
            type: 'item',
            url: '/third-party/recharts/radar',
          },
          {
            id: 'radial',
            title: 'Radial Chart',
            messageId: 'sidebar.recharts.radialChart',
            type: 'item',
            url: '/third-party/recharts/radial',
          },
          {
            id: 'scatter',
            title: 'Scatter Chart',
            messageId: 'sidebar.recharts.scatterChart',
            type: 'item',
            url: '/third-party/recharts/scatter',
          },
          {
            id: 'funnel',
            title: 'Funnel Chart',
            messageId: 'sidebar.recharts.funnelChart',
            type: 'item',
            url: '/third-party/recharts/funnel',
          },
          {
            id: 'treemap',
            title: 'Treemap Chart',
            messageId: 'sidebar.recharts.treeChart',
            type: 'item',
            url: '/third-party/recharts/treemap',
          },
        ],
      },
      {
        id: 'calendar',
        title: 'Big Calendar',
        messageId: 'sidebar.bigCalender',
        type: 'collapse',
        icon: 'calendar_today',
        children: [
          {
            id: 'basic',
            title: 'Basic',
            messageId: 'sidebar.bigCalender.basic',
            type: 'item',
            url: '/calendar/basic',
          },
          {
            id: 'cultures',
            title: 'Cultures',
            messageId: 'sidebar.bigCalender.cultures',
            type: 'item',
            url: '/calendar/cultures',
          },
          {
            id: 'dnd',
            title: 'Dnd',
            messageId: 'sidebar.bigCalender.dnd',
            type: 'item',
            url: '/calendar/dnd',
          },
          {
            id: 'popup',
            title: 'Popup',
            messageId: 'sidebar.bigCalender.popup',
            type: 'item',
            url: '/calendar/popup',
          },
          {
            id: 'rendering',
            title: 'Rendering',
            messageId: 'sidebar.bigCalender.rendering',
            type: 'item',
            url: '/calendar/rendering',
          },
          {
            id: 'selectable',
            title: 'Selectable',
            messageId: 'sidebar.bigCalender.selectable',
            type: 'item',
            url: '/calendar/selectable',
          },
          {
            id: 'timeslots',
            title: 'Timeslots',
            messageId: 'sidebar.bigCalender.timeSlots',
            type: 'item',
            url: '/calendar/timeslots',
          },
        ],
      },
      {
        id: 'react-color',
        title: 'React Color',
        messageId: 'sidebar.reactColor',
        type: 'item',
        icon: 'invert_colors',
        url: '/third-party/react-color',
      },
      {
        id: 'react-dropzone',
        title: 'React Dropzone',
        messageId: 'sidebar.reactDropzone',
        type: 'item',
        icon: 'attach_file',
        url: '/third-party/react-dropzone',
      },
      {
        id: 'react-notification',
        title: 'React Notification',
        messageId: 'sidebar.reactNotification',
        type: 'item',
        icon: 'notifications_none',
        url: '/third-party/react-notification',
      },
      {
        id: 'react-table',
        title: 'React Table',
        messageId: 'sidebar.reactTable',
        type: 'item',
        icon: 'table_chart',
        url: '/third-party/react-table',
      },
      {
        id: 'material-table',
        title: 'Material Table',
        messageId: 'sidebar.materialTable',
        type: 'item',
        icon: 'table',
        url: '/third-party/material-table',
      },
      {
        id: 'react-gallery',
        title: 'Gallery',
        messageId: 'sidebar.gallery',
        type: 'item',
        icon: 'collections',
        url: '/third-party/react-gallery',
      },
      {
        id: 'react-dnd',
        title: 'React DND',
        messageId: 'sidebar.reactDnd',
        type: 'item',
        icon: 'control_camera',
        url: '/third-party/react-dnd',
      },
      {
        id: 'react-player',
        title: 'Player',
        messageId: 'sidebar.player',
        type: 'item',
        icon: 'theaters',
        url: '/third-party/react-player',
      },
    ],
  },
  {
    id: 'pages',
    title: 'Pages',
    messageId: 'sidebar.pages',
    type: 'group',
    children: [
      {
        id: 'timeline',
        title: 'Time Line',
        messageId: 'sidebar.pages.timeLine',
        type: 'item',
        icon: 'timeline',
        url: '/third-party/time-line',
      },
      {
        id: 'extra-pages',
        title: 'Extra Pages',
        messageId: 'sidebar.pages.extraPages',
        type: 'collapse',
        icon: 'work_outline',
        children: [
          {
            id: 'about-us',
            title: 'About Us',
            messageId: 'sidebar.pages.extraPages.aboutUs',
            type: 'item',
            url: '/extra-pages/about-us',
          },
          {
            id: 'knowledge-base',
            title: 'Knowledge Base',
            messageId: 'sidebar.pages.extraPages.knowledgeBase',
            type: 'item',
            url: '/extra-pages/knowledge-base',
          },
          {
            id: 'portfolio',
            title: 'Portfolio',
            messageId: 'sidebar.pages.extraPages.portfolio',
            type: 'item',
            url: '/extra-pages/portfolio',
          },
          {
            id: 'faq',
            title: 'FAQ',
            messageId: 'sidebar.pages.extraPages.faq',
            type: 'item',
            url: '/extra-pages/faq',
          },
        ],
      },
      {
        id: 'user',
        title: 'User Pages',
        messageId: 'sidebar.pages.userPages',
        type: 'collapse',
        icon: 'verified_user',
        children: [
          {
            id: 'sign-in-1',
            title: 'SignIn-1',
            messageId: 'sidebar.pages.userPages.signIn1',
            type: 'item',
            url: '/user/sign-in-1',
          },
          {
            id: 'sign-in-2',
            title: 'SignIn-2',
            messageId: 'sidebar.pages.userPages.signIn2',
            type: 'item',
            url: '/user/sign-in-2',
          },
          {
            id: 'sign-up-1',
            title: 'SignUp-1',
            messageId: 'sidebar.pages.userPages.signUp1',
            type: 'item',
            url: '/user/sign-up-1',
          },
          {
            id: 'sign-up-2',
            title: 'SignUp-2',
            messageId: 'sidebar.pages.userPages.signUp2',
            type: 'item',
            url: '/user/sign-up-2',
          },
          {
            id: 'forgot-password-1',
            title: 'Forgot Password-1',
            messageId: 'sidebar.pages.userPages.forgetPassword1',
            type: 'item',
            url: '/user/forgot-password-1',
          },
          {
            id: 'forgot-password-2',
            title: 'Forgot Password-2',
            messageId: 'sidebar.pages.userPages.forgetPassword2',
            type: 'item',
            url: '/user/forgot-password-2',
          },
          {
            id: 'reset-password-1',
            title: 'Reset Password-1',
            messageId: 'sidebar.pages.userPages.resetPassword1',
            type: 'item',
            url: '/user/reset-password-1',
          },
          {
            id: 'reset-password-2',
            title: 'Reset Password-2',
            messageId: 'sidebar.pages.userPages.resetPassword2',
            type: 'item',
            url: '/user/reset-password-2',
          },
          {
            id: 'lock-1',
            title: 'Lock Screen-1',
            messageId: 'sidebar.pages.userPages.lockScreen1',
            type: 'item',
            url: '/user/lock-1',
          },
          {
            id: 'lock-2',
            title: 'Lock Screen-2',
            messageId: 'sidebar.pages.userPages.lockScreen2',
            type: 'item',
            url: '/user/lock-2',
          },
        ],
      },
      {
        id: 'list-type',
        title: 'User List',
        messageId: 'sidebar.pages.userList',
        type: 'collapse',
        icon: 'view_list',
        children: [
          {
            id: 'morden',
            title: 'Modern',
            messageId: 'sidebar.pages.userList.modern',
            type: 'item',
            url: '/list-type/morden',
          },
          {
            id: 'standard',
            title: 'Standard',
            messageId: 'sidebar.pages.userList.standard',
            type: 'item',
            url: '/list-type/standard',
          },
          {
            id: 'flat',
            title: 'Flat',
            messageId: 'sidebar.pages.userList.flat',
            type: 'item',
            url: '/list-type/flat',
          },
        ],
      },
      {
        id: 'error-pages',
        title: 'Error Pages',
        messageId: 'sidebar.pages.errorPages',
        type: 'collapse',
        icon: 'report',
        children: [
          {
            id: 'error-404',
            title: '404',
            messageId: 'sidebar.pages.errorPages.404',
            type: 'item',
            url: '/error-pages/error-404',
          },
          {
            id: 'error-500',
            title: '500',
            messageId: 'sidebar.pages.errorPages.500',
            type: 'item',
            url: '/error-pages/error-500',
          },
          {
            id: 'maintenance',
            title: 'Maintenance',
            messageId: 'sidebar.pages.errorPages.maintenance',
            type: 'item',
            url: '/error-pages/maintenance',
          },
          {
            id: 'coming-soon',
            title: 'Coming Soon',
            messageId: 'sidebar.pages.errorPages.comingSoon',
            type: 'item',
            url: '/error-pages/coming-soon',
          },
        ],
      },
      {
        id: 'multi-level',
        title: 'Multi Level',
        messageId: 'sidebar.multiLevel',
        type: 'collapse',
        icon: 'menu',
        children: [
          {
            id: 'level-1',
            title: 'Level 1',
            messageId: 'sidebar.multiLevel.level1',
            type: 'item',
            url: '/menu-level-1',
          },
          {
            id: 'level-2',
            title: 'Level 1',
            messageId: 'sidebar.multiLevel.level1',
            type: 'collapse',
            children: [
              {
                id: 'level-2-1',
                title: 'Level 2',
                messageId: 'sidebar.multiLevel.level2',
                type: 'item',
                url: '/menu-level-2-1',
              },
              {
                id: 'level-2-2',
                title: 'Level 2',
                messageId: 'sidebar.multiLevel.level2',
                type: 'item',
                url: '/menu-level-2-2',
              },
            ],
          },
          {
            id: 'level-3',
            title: 'Level 1',
            messageId: 'sidebar.multiLevel.level1',
            type: 'collapse',
            children: [
              {
                id: 'level-3-1',
                title: 'Level 2',
                messageId: 'sidebar.multiLevel.level2',
                type: 'collapse',
                children: [
                  {
                    id: 'level-3-1-1',
                    title: 'Level 3',
                    messageId: 'sidebar.multiLevel.level3',
                    type: 'item',
                    url: '/menu-level-3-1-1',
                  },
                  {
                    id: 'level-3-1-2',
                    title: 'Level 3',
                    messageId: 'sidebar.multiLevel.level3',
                    type: 'item',
                    url: '/menu-level-3-1-2',
                  },
                ],
              },
              {
                id: 'level-3-2',
                title: 'Level 2',
                messageId: 'sidebar.multiLevel.level2',
                type: 'collapse',
                children: [
                  {
                    id: 'level-3-2-1',
                    title: 'Level 3',
                    messageId: 'sidebar.multiLevel.level3',
                    type: 'item',
                    url: '/menu-level-3-2-1',
                  },
                  {
                    id: 'level-3-2-2',
                    title: 'Level 3',
                    messageId: 'sidebar.multiLevel.level3',
                    type: 'item',
                    url: '/menu-level-3-2-2',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
export default routesConfig;
