import {
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
  UPDATE_NEW_USER,
} from '../../shared/constants/ActionTypes';
import * as actions from '../actions/action.types';
import {socket} from 'socket';

const INIT_STATE = {
  user: null,
  token: null,
  message: null,
  error: null,
  newUser: {},
  loading: false,
  subscriptionTokens:[],
};
let payload = null;
const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case UPDATE_NEW_USER:
      return {
        ...state,
        newUser: action.payload,
      };

    case SIGNOUT_AUTH_SUCCESS: {
      return {
        ...state,
        user: null,
      };
    }
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case 'FORGOT_PASSWORD':
      payload = action.payload;
      return {...state, payload};
    case actions.SET_AUTH_ERROR_TO_NULL:
      return {
        ...state,
        error: null,
      };
    case 'CREATE_NOTIFICATION_SUBSCRIPTION':
      return {...state, subscriptionTokens: [...state.subscriptionTokens, action.payload]};
    case 'LOGIN':
      payload = action.payload;
      let error = null;
      if (payload.token) {
        localStorage.setItem('auth-token', payload.token);
      }
      if (payload.user) {
        socket.loginAction(payload.user.id);
        console.log(payload.subscriptionTokens);
        localStorage.setItem('current-user', JSON.stringify(payload.user));
        localStorage.setItem('subscriptionTokens', payload.subscriptionTokens)
      }
      if (payload.error) error = payload.error.data.message;
      return {...state, user: payload.user, token: payload.token, 
                 subscriptionTokens: payload.subscriptionTokens, error: error};
    case 'RESET_PASSWORD':
      payload = action.payload;
      if (payload.error) {
        return {...state, ...payload};
      } else {
        return {...state, ...payload, error: payload.error};
      }

    case 'REGISTER':
      payload = action.payload;
      return {...state, ...payload};
    case actions.SET_CURRENT_USER:
      payload = action.payload;
      if (payload.action) {
        return {...state, error: payload.error};
      }
      return {...state, user: payload};

    case 'SET_LOADING':
      return {...state, loading: true};

    case actions.UPDATE_USER:
      payload = action.payload;
      console.log(payload);
      if (payload.error) {
        return {
          ...state,
          loading: false,
          error: 'There was an error updating the user',
        };
      }
      const user = {...state.user, ...payload.newData};
      localStorage.setItem('current-user', JSON.stringify(user));
      return {...state, user, loading: false};
    default:
      return state;
  }
};
export default authReducer;


