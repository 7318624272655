export const ERROR = 'ERROR';
export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const CATEGORY_GET_ALL = 'CATEGORY_GET_ALL';
export const USERS_GET_ALL = 'USERS_GET_ALL';
export const PREFERENCES = 'PREFERENCES';
export const DYNAMIC = 'DYNAMIC';
export const QUIZ = 'QUIZ';
export const CREATE_SESSION = 'CREATE_SESSION';
export const GET_SESSION_DATA = 'GET_SESSION_DATA';
export const CREATE_TOOL = 'CREATE_TOOL';
export const GET_TOOL_DATA = 'GET_TOOL_DATA';
export const GET_LIST_DATA = 'GET_LIST_DATA';
export const CREATE_TITLE = 'CREATE_TITLE';
export const GET_CONTENT_DATA = 'GET_CONTENT_DATA';
export const UPDATE_CONTENT_DATA = 'UPDATE_CONTENT_DATA';
export const CREATE_USER_POST = 'CREATE_USER_POST';
export const GET_USER_POSTS = 'GET_USER_POSTS';
export const NEW_GET_USER_POSTS = 'NEW_GET_USER_POSTS';
export const DELETE_USER_POST = 'DELETE_USER_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const GET_POST_COMMENTS = 'GET_POST_COMMENTS';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_POST = 'UPDATE_USER_POST';
export const GET_ALL_TOOLS_DATA = 'GET_ALL_TOOLS_DATA';
export const SET_AUTH_ERROR_TO_NULL = 'SET_AUTH_ERROR_TO_NULL';
export const SET_SELECTED_TOOL = 'SET_SELECTED_TOOL';
export const SET_SELECTED_QUIZ = 'SET_SELECTED_QUIZ';
export const GET_QUIZ_DATA = 'GET_QUIZ_DATA';
export const GET_ALL_QUIZ_DATA = 'GET_ALL_QUIZ_DATA';
export const CREATE_QUIZ = 'CREATE_QUIZ';
export const GET_ALL_COMPLETE_QUESTIONS = 'GET_ALL_COMPLETE_QUESTIONS';
export const GET_ALL_BANK_QUESTIONS = 'GET_ALL_BANK_QUESTIONS';
export const Edit_QUIZ = 'Edit_QUIZ';
export const GET_POST_BY_ID = 'GET_POST_BY_ID';
export const setPermissions = 'setPermissions';
export const CREATE_Resource = 'createResource';
export const GET_Resource_DATA = 'GET_RESOURCE_DATA';
export const SET_SELECTED_TOOLS = 'SET_SELECTED_TOOLS';
export const Create_journal = 'Create_journal';
export const SET_LOADING = 'SET_LOADING';
export const SET_COMMON_LOADING = 'SET_COMMON_LOADING;';
